<template>
    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-spinner">
        <div class="spinner"></div>
    </div>

    <!-- Main Content -->
    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Deine E-Mail-Adresse wurde erfolgreich bestätigt.</h1>
                    <p class="page-description" style="margin-top: 32px; margin-bottom: 32px;">
                        In Kürze erhältst du per Post einen Brief mit einem PIN-Code (QR-Code) zur 
                        Aktivierung deines digitalen Briefkastens. Durch Scannen des QR-Codes stellen 
                        wir sicher, dass nur du Zugriff zu deinem digitalen Briefkasten bei nolas hast.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import ShieldImage from '@/assets/confirm-icon.svg';

export default {
    name: 'ExistingCustomerAuthentication',
    components: {
        AppNavbar,
    },
    data() {
        return {
            imageSrc: ShieldImage,
            loading: true,
            imageLoaded: false,
        };
    },
    mounted() {
        this.openDeepLink();
        const img = new Image();
        img.onload = () => {
            this.imageLoaded = true;
            this.checkIfLoaded();
        };
        img.onerror = () => {
            console.error('Failed to load image.');
            this.imageLoaded = true;
            this.checkIfLoaded();
        };
        img.src = this.imageSrc;
    },
    methods: {
        checkIfLoaded() {
            if (this.imageLoaded) {
                this.loading = false;
            }
        },
        openDeepLink() {
            const deepLink = `myapp:///verification-required`;
            window.location.href = deepLink;
        },
    },
};
</script>

<style scoped>
.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

button:hover {
    background-color: #0056b3;
}

.btn-blue {
    font-size: 16px !important;
    background-color: #4373F0;
    color: white;
}

.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
