<template>
  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-spinner">
    <div class="spinner"></div>
  </div>

  <!-- Main Content -->
  <div v-else class="p-0 container-color">
    <div class="content">
      <AppNavbar style="background-color: #FEFAF2; color: black;" />
      <div class="d-flex justify-content-center" style="margin-top: 0px;">
        <div class="inner-content text-center" style="margin-top: 53px;">
          <img :src="imageSrc" style="width: 92px; height: 92px; margin-bottom: 28px;" alt="Descriptive Text">
          <h1 class="page-title">Wir sind auf dem Weg zu dir</h1>
          <p class="page-description">Wir haben den Bestätigungslink an folgende E-Mail-Adresse gesendet:</p>
          <p class="page-description" style="font-weight: 700;">{{ email }}</p>
          <p class="page-description" style="margin-bottom: 8px !important;">Bitte klicke auf den Link in der E-Mail, um
            deine E-Mail-Adresse zu bestätigen.</p>
          <!-- Resend Email Section -->
          <div class="resend-email-section" style="margin-top: 134px; margin-bottom: 30px;">
            <p style="padding-bottom: 0px;  font-weight: 400; font-size: 16px; margin-bottom: 0px;" >E-Mail nicht
              erhalten?</p>
            <a href="#" class="resend-link" style="color: #6A70FF; font-weight: 500;">Erneut senden</a>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import MismatchImage from '@/assets/Frame 627850.svg';
import axios from 'axios';


export default {
  name: 'NewCusEmailSent',
  components: {
    AppNavbar,
    AppFooter
  },
  data() {
    return {
      imageSrc: MismatchImage,
      email: '',
      loading: true, // Added loading state
    };
  },
  methods: {
    async sendVerificationEmail() {
      const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email') || '';
        const token = urlParams.get('token') || '';
      try {
        const response = await axios.post('/api/customers/verify/account/send', { email, token });
        console.log('Verification email sent:', response.data);
      } catch (error) {
        console.error('Error sending verification email:', error.response?.data || error.message);
      }
    }
  },
  mounted() {
    // Retrieve the email from local storage
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      this.email = storedEmail;
    } else {
      this.email = 'No email found'; // Fallback in case the email is not found
    }

    // Preload the image
    const img = new Image();
    img.onload = () => {
      this.loading = false; // Hide the loading spinner
    };
    img.onerror = () => {
      console.error('Failed to load image.');
      this.loading = false; // Hide the spinner even if the image fails to load
    };
    img.src = this.imageSrc;

    // Clear local storage when a new tab is opened
    sessionStorage.setItem('sessionActive', 'true');
    window.addEventListener("storage", (event) => {
      if (event.key === "sessionActive") {
        localStorage.clear();
      }
    });

    // Clear local storage when the tab is closed or user navigates away
    window.addEventListener("beforeunload", () => {
      localStorage.clear();
      sessionStorage.removeItem('sessionActive');
    });
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === null) {
      localStorage.clear();
      next('/start');
    } else {
      next();
    }
  },
  beforeUnmount() {
    // Also clear local storage when the component is destroyed
    localStorage.clear();
  }
};
</script>

<style scoped>
.container {
  text-align: center;
}

.container-color {
  background-color: #FEFAF2;
}

.inner-content {
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 36px;
  max-width: 500px;
}

.content h1 {
  font-size: 35px;
  margin: 16px 0;
}

.content p {
  font-size: 16px;
  padding: 0px 10px;
  color: black;
  margin-bottom: 30px;
}

.btn-blue {
  background-color: #4373F0;
  color: white;
}

.resend-email-section {
  margin-top: 134px;
}

.resend-link {
  color: #4373F0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.resend-link:hover {
  text-decoration: underline;
}

/* Spinner Styles */
.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #FEFAF2;
  /* Optional: match the background color */
}

.spinner {
  border: 8px solid #6a70ff;
  border-top: 12px solid #fefaf2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>