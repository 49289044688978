import Vue from 'vue';
import VueRouter from 'vue-router';
import StartView from '../views/StartView.vue'
import LinkExpiredView from '../views/linkExpiredView.vue'
import TokenInvalidView from '../views/TokenInvalidView.vue'
import UserAlreadyRegisteredView from '../views/UserAlreadyRegistredView.vue'
// import Variant1View from '../views/Variant1View.vue'
import Variant2View from '../views/Variant2View.vue'
// import Variant3View from '../views/Variant3View.vue'
import CustomersView from '../views/CustomersView.vue'
import ExistingCustomerView from '../views/ExistingCustomerView.vue'
import NewCustomerReg from '../views/NewCustomerView.vue'
// import NewCustomerReg from '../views/NewCustomerReg.vue'
import SetNewPassword from '../views/SetNewPass.vue'
import AddressMismatch from '../views/AddressMismactch.vue'
import AuthConfirmed from '../views/Authconfirmed.vue'
import VerificationRequired from '../views/verificationRequired.vue'
import ExistingCustomerAuthentication from '../views/ExistingCusAuthentication.vue'
import ConfirmationWaitingView from '../views/ConfirmationWaitingView.vue'
// import AuthConfirmedForgorPassword from '../views/auth-confirmedForgotPass.vue'
import PostmailboxConfirmed from '../views/PostmailboxConfirmedView.vue'
// import ForgotPassView from '../views/Forgotpassview.vue'
// import CheckEmailView from '../views/CheckEmailview.vue'
import PasswordResetSuccessView from '../views/PasswordResetSuccessView.vue'
import NewCusEmailSent from '../views/NewCusMailSentView.vue'
// import NewCusEmailSentSuccess from '../views/NewCusEmailConSuccess.vue'
import NewCusRegConfirmed from '../views/NewCusRegConfirmed.vue'
import ForetPasswordEmailSentView from '../views/ForgetPassEmailSentView.vue'
// import AppSessionTest from '../views/appSessionTest.vue'

// import DevView from '../views/DevView.vue'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      // blue screen named varient 2
      path: '/',
      component: Variant2View,
    },
    {
      // start page for any user 
      path: '/start',
      name: "startpage",
      component: StartView
    },
    {
      // link expired
      path: '/link-expired',
      component: LinkExpiredView
    },
    {
      // token expired
      path: '/email-address-already-verified',
      component: TokenInvalidView
    },
    {
      // token expired
      path: '/user-already-verified',
      component: UserAlreadyRegisteredView
    },
    {
      // nolas account have or not asking page for any user
      path: '/customerview',
      name: "CustomersView",
      component: CustomersView,
      beforeEnter: (to, from, next) => {
        if (from.path == '/' || from.path == '/existing' || from.path == '/newcustomer') {
          next();
        } else {
          next('/');
        }
      }
    },
    {
      // new customer registration mail send form fill view 
      path: '/new-customer',
      component: NewCustomerReg,
      // beforeEnter: (to, from, next) => {
      //   if (from.path !== '/customerview') {
      //     next('/');
      //   } else {
      //     next();
      //   }
      // }
    },
    {
      //new customer registration mail sent view 
      path: '/new-customer-email-sent',
      component: NewCusEmailSent,
      // beforeEnter: (to, from, next) => {
      //   if (from.path !== '/newcustomer') {
      //     next('/');
      //   } else {
      //     next();
      //   }
      // }
    },
    {
      // existing customers authentication required view 
      path: '/existing-auth-confirmed',
      component: ExistingCustomerAuthentication
    },
    {
      // existing customers authentication required view 
      path: '/confirmationwaiting',
      component: ConfirmationWaitingView
    },
    {
      // existing customers login view 
      path: '/existing',
      component: ExistingCustomerView
    },
    {
      // authentication confirmed view
      path: '/auth-confirmed',
      component: AuthConfirmed
    },
    {
      // authentication confirmed view
      path: '/new-customer-registration-confirmed',
      component: NewCusRegConfirmed
    },
    {
      // authentication confirmed view
      path: '/postal-mailbox-confirmed',
      component: PostmailboxConfirmed
    },
    {
      // authentication confirmed view
      path: '/set-newpassword',
      component: SetNewPassword
    },
    {
      // authentication confirmed view
      path: '/password-reset-success',
      component: PasswordResetSuccessView
    },
    // {
    //   path: '/foretpasswordemailsent',
    //   component: ForetPasswordEmailSentView,
    // },
    {
      // start page for any user 
      path: '/address-mismatch',
      component: AddressMismatch
    },
    {
      // start page for any user 
      path: '/verification-required',
      component: VerificationRequired
    },
    {
      // start page for any user 
      path: '/forget-password-email',
      component: ForetPasswordEmailSentView
    },
    {
      // start page for any user 
      path: '/password-reset-success',
      component: PasswordResetSuccessView
    },
    // {
    //   // authentication confirmed view
    //   path: '/app-session-test',
    //   component: AppSessionTest
    // },
    // {
    //   // authentication confirmed view
    //   path: '/checkemail',
    //   components: CheckEmailView
    // },

  ]
})

// const routes = [
//   {
//     path: '/dev',
//     component: DevView
//   },
//   {
//     path: '/variant1',
//     component: Variant1View
//   },
//   {
//     path: '/variant2',
//     component: Variant2View
//   },
//   {
//     path: '/variant3',
//     component: Variant3View
//   },
//   {
//     path: '/existing',
//     name: 'ExistingCustomerView',
//     component: ExistingCustomerView
//   },
//   {
//     path: '/newcustomerreg',
//     name: 'NewCustomerReg',
//     component: NewCustomerReg
//   },
//   {
//     path: '/setnewpassword',
//     name: 'SetNewPassword',
//     component: SetNewPassword
//   },
//   {
//     path: '/credentialsmismatch',
//     name: 'CredentailsMismatch',
//     component: CredentailsMismatch
//   },
//   {
//     path: '/auth-confirmed',
//     name: 'AuthConfirmed',
//     component: AuthConfirmed
//   },
//   {
//     path: '/auth-confirmedforgotpass',
//     name: 'AuthConfirmedForgorPassword',
//     component: AuthConfirmedForgorPassword
//   },
//   {
//     path: '/emailconfirmation',
//     name: 'ConfirmationView',
//     component: ConfirmationWaitingView
//   },
//   {
//     path: '/postmailboxconfirmed',
//     name: 'PostmailboxConfirmed',
//     component: PostmailboxConfirmed
//   },
//   {
//     path: '/forgotpass',
//     name: 'ForgotPassView',
//     component: ForgotPassView
//   },
//   {
//     path: '/checkemail',
//     name: 'CheckEmailView',
//     component: CheckEmailView
//   },
//   {
//     path: '/passresetsuccess',
//     name: 'PasswordResetSuccessView',
//     component: PasswordResetSuccessView
//   },
//   {
//     path: '/newcusemailsuccess',
//     name: 'NewCusEmailSentSuccess',
//     component: NewCusEmailSentSuccess
//   },
//   {
//     path: '/newcusregconfirmed',
//     name: 'NewCusRegConfirmed',
//     component: NewCusRegConfirmed
//   },
//   {
//     path: '/postmailboxconfirmed',
//     name: 'PostmailboxConfirmed',
//     component: PostmailboxConfirmed
//   },
//   {
//     path: '/foretpasswordemailsent',
//     name: 'ForetPasswordEmailSentView',
//     component: ForetPasswordEmailSentView
//   },

// ];

export default router

// export default new VueRouter({
//   mode: 'history',
//   routes,
// });
