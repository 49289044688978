<template>

    <!-- spinner content -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner">
            </div>
        </div>
    </div>

    <!-- main content -->
    <div v-else class="p-0">
        <div class="content" style="height: calc(100svh - 97px);">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center">
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Prüfe deine <br> E-Mails</h1>
                    <p class="page-description" style="margin-top: 32px;">Die Anweisungen zum Zurücksetzen deines
                        Passworts wurden an '' gesendet, wenn ein Konto registriert ist. Wenn du keine E-Mail erhalten
                        hast, überprüfe die Schreibweise der E-Mail-Adresse oder lass dir eine neue E-Mail zusenden.
                    </p>
                    <div class="col-md-12 btn-wrap p-0" style="margin-bottom: 34px;">
                        <button class="btn btn-blue m-0" @click="goBack">Zurück zum Login</button>
                    </div>
                    <div class="p-0">
                        <a @click="emailSend" class="underline" style="margin-bottom: 32px; cursor: pointer;">Erneut senden</a>
                    </div>
                </div>
            </div>

        </div>
        <AppFooter />
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import EmailImage from '@/assets/mail-icon.svg';
import axios from 'axios';



export default {
    name: 'ForetPasswordEmailSentView',
    components: {
        AppNavbar,
        AppFooter
    },
    methods: {
        emailSend() {
            if (this.email != '') {
                this.loading = true
                const apiUrl = '/api/customers/checkEmail'
                const body = {
                    email: this.email
                }

                axios.post(apiUrl, body)
                    .then(response => {
                        if (response.status == 200 && response.data.message == "success") {
                            this.$router.push('/forget-password-email');
                        }

                    })
                    .catch(err => {
                        this.errorMessage = "SBitte versuche es noch einmal, etwas stimmt nicht."
                        console.log(err);

                    })
                    .finally(() => {
                        this.loading = false
                    })

            } else {
                this.errorMessage = "Gib die E-Mail für die Passwortzurücksetzung ein."
            }
        },
        goBack() {
            this.$router.push('/existing');
        },
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        },
        checkIfLoaded() {
            if (this.dataLoad) {
                this.loading = false
            }
        }
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: EmailImage,
            loading: true,
            dataLoad: false,
            email: '',
        }
    },
    created() {    
        this.email = this.$route.query.email || '';
        this.preLoadImage(EmailImage)
            .then(() => {
                this.dataLoad = true;
                this.checkIfLoaded();
            })
            .catch(() => {
                console.error('Failed to load background image');
                this.checkIfLoaded();
            })
    }
};
</script>

<style scoped>
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}
</style>