<template>
    <!-- Loading Spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner"></div>
        </div>
    </div>

    <!-- Main Content -->
    <div v-else class="p-0 container-color ">
        <div class="content" style="height: calc(100svh - 97px);">
            <AppNavbar style="background-color: #FEFAF2; color: black;" />
            <div class="d-flex justify-content-center" >
                <div class="inner-content text-center">
                    <img :src="imageSrc" style="margin-bottom: 28px;" alt="Descriptive Text">
                    <h1 class="page-title">Boom! Wir warten nun auf deine Bestätigung</h1>
                    <p class="page-description" style="margin-top: 32px;">In Kürze erhältst du per Post einen Brief mit
                        einem PIN-Code (QR-Code) zur Aktivierung deines digitalen Briefkastens. Durch Scannen des
                        QR-Codes stellen wir sicher, dass nur du Zugriff zu deinem digitalen Briefkasten bei nolas hast.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import EmailImage from '@/assets/mail-icon.svg'

export default {
    name: 'ConfirmationWaitingView',
    components: {
        AppNavbar,
    },
    data() {
        return {
            // Referencing the imported image
            imageSrc: EmailImage,
            loading: true,
            dataLoaded: false
        }
    },
    methods: {
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src;
            });
        },
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false;
            }
        }
    },
    created() {
        // Preload the image
        this.preLoadImage(this.imageSrc)
            .then(() => {
                this.dataLoaded = true;
                this.checkIfLoaded();
            })
            .catch(() => {
                console.error('Failed to load image');
                this.checkIfLoaded();
            });
    }
};
</script>

<style scoped>
/* Spinner Styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.container {
    text-align: center;
}

.container-color {
    background-color: #FEFAF2;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    /* margin-bottom: 53px; */
    margin-top: 36px;
    max-width: 500px;
}

.content h1 {
    font-size: 35px;
    margin: 16px 0;
}

.content p {
    font-size: 16px;
    padding: 0px 10px;
    color: black;
    margin-bottom: 20px;
}

form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}

button {
    padding: 10px;
    background-color: #4373F0;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>
