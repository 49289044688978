<template>

    <!-- spinner -->
    <div v-if="loading">
        <div class="loading-spinner">
            <div class="spinner">
            </div>
        </div>
    </div>

    <!-- Main content -->
    <div v-else class="p-0 container-color">
        <div class="content">
            <AppNavbar />
            <div class="d-flex justify-content-center" style="padding-bottom: 20px;">
                <div class="inner-content ">
                    <div class="row m-0 p-0">
                        <h1 class="page-title p-0">Richte jetzt deinen digitalen Briefkasten ein.</h1>
                        <p class="page-description">Bitte gib deine E-Mail-Adresse an, um deinen digitalen Briefkasten
                            einzurichten..
                        </p>
                        <p class="page-description">Wenn du auf "Fertig" klickst, erhältst du eine E-Mail, um deine
                            E-Mail-Adresse zu bestätigen.</p>
                        <div class="p-0" style="margin-bottom: 20px;">
                            <form @submit.prevent="submitForm">
                                <div>
                                    <input class="form-control w-100" v-model="email" type="email"
                                        placeholder="E-Mail-Adresse">
                                </div>
                                <div>
                                    <input class="form-control w-100" v-model="repeatEmail" type="email"
                                        placeholder="E-Mail-Adresse wiederholen">
                                </div>
                                <div>
                                    <input class="form-control w-100" v-model="password" type="password"
                                        placeholder="Passwort">
                                </div>
                                <div>
                                    <input class="form-control w-100" style="margin-bottom: 0px;"
                                        v-model="repeatPassword" type="password" placeholder="Passwort wiederholen">
                                </div>

                                <div v-if="errorMessage" class="error-message" style="color: red; padding-top: 5px;">
                                    {{ errorMessage }}
                                </div>
                            </form>
                        </div>
                        <div class="terms-container">
                            <div class="checkbox-wrapper">
                                <input type="checkbox" id="termsCheckbox" class="custom-checkbox"
                                    v-model="termsChecked">
                                <label for="termsCheckbox" class="terms-label">
                                    Ich habe die <a href="#" style="color: #6A70FF;">allgemeinen
                                        Geschäftsbedingungen</a> von nolas gelesen und stimme ihnen zu
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12 btn-wrap p-0">
                            <button class="btn btn-blue m-0" @click="submitForm">
                                <img :src="imageSrc3" style="margin-bottom: 2px; margin-right: 6px;"
                                    alt="Descriptive Text">
                                Fertig</button>
                        </div>
                        <div class="text-center" style="margin-top: 34px;">
                            <img :src="imageSrc2" style="margin-right: 10px;" alt="Descriptive Text">
                            <span class="underline">
                                <a @click="goBack" style="cursor: pointer;">Zurück</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>

</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import Icon from '@/assets/back-icon-black.svg'
import tickIcon from '@/assets/Icon(2).svg'
import axios from 'axios';


export default {
    name: 'NewCustomerReg',
    components: {
        AppNavbar,
        AppFooter
    },
    data() {
        return {
            email: '',
            repeatEmail: '',
            password: '',
            repeatPassword: '',
            passwordError: '',
            errorMessage: '',
            termsChecked: false,
            imageSrc2: Icon,
            imageSrc3: tickIcon,
            loading: true, // Spinner is displayed initially
            dataLoaded: false, // Tracks when the image is fully loaded
        };
    },
    methods: {
        // goToWelcome() {
        //     this.$router.push('/newcusemailsent');
        // },

        validatePassword() {
            const password = this.password
            const minLength = /.{8,}/;
            const uppercase = /[A-Z]/;
            const lowercase = /[a-z]/;
            const digits = /\d.*\d/;
            const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

            if (!minLength.test(password)) {
                this.errorMessage = 'Das Passwort muss mind. 8 Zeichen lang sein.'
            } else if (!uppercase.test(password)) {
                this.errorMessage = 'Muss mind. einen Großbuchstaben enthalten.'
            } else if (!lowercase.test(password)) {
                this.errorMessage = 'Muss mind. einen Kleinbuchstaben enthalten.'
            } else if (!digits.test(password)) {
                this.errorMessage = 'Muss mind. zwei Zahlen enthalten.'
            } else if (!specialChar.test(password)) {
                this.errorMessage = 'Muss mind. ein Sonderzeichen enthalten.'
            } else {
                this.errorMessage = '';
            }
        },
        submitForm() {

            if (!this.termsChecked) {
                this.errorMessage = 'Du musst den AGBs zustimmen, um mit dem Registrierungsprozess fortzufahren.';
                return;
            }

            this.validatePassword()
            if (!this.errorMessage && this.password == this.repeatPassword && this.email == this.repeatEmail) {

                // const dataObject = JSON.parse(localStorage.getItem('requestParams'));
                // Step 1: Check if the emails match
                // if (this.email !== this.repeatEmail) {
                //     this.errorMessage = 'Email addresses do not match.';
                //     return;
                // }

                // // Step 2: Compare email with the one stored in local storage
                // if (this.email !== dataObject.email) {
                //     this.errorMessage = 'The entered email does not match the one on record.';
                //     return;
                // }

                localStorage.setItem('userEmail', this.email);

                // Step 3: Make an API request if both checks are successful
                this.errorMessage = '';
                this.makeApiRequest();
            } else if (this.password != this.repeatPassword) {
                this.errorMessage = 'Passwörter stimmen nicht überein.'
            } else if ((this.email != this.repeatEmail)) {
                this.errorMessage = 'E-Mail-Adressen stimmen nicht überein.'
            }

        },
        makeApiRequest() {

            const dataObject = JSON.parse(localStorage.getItem('requestParams'));
            // Replace with your actual API endpoint and request details
            const apiUrl = '/api/customers/register';

            const body = {
                email: this.email,
                password: this.password, // Replace with actual password handling if necessary,
                token: dataObject.token
                // country: dataObject.country,
                // address_line_1: dataObject.address_line_1,
                // city: dataObject.city,
                // postalCode: dataObject.postalCode
            }

            axios.post(apiUrl, body)
                .then(response => {
                    if (response.status == 201) {
                        this.$router.push('/new-customer-email-sent');
                    } else if (response.status == 200 && response.data.type == "EmailAlreadyUsedError") {
                        this.errorMessage = response.data.message;
                    } else if (response.status == 200 && response.data.type == "CustomerDataTokenUsedError") {
                        this.errorMessage = response.data.message;
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.errorMessage = 'Abgabe fehlgeschlagen. Bitte versuche es später noch einmal.';
                });
        },
        goBack() {
            window.history.back();  // Goes back to the previous page
        },
        checkIfLoaded() {
            if (this.dataLoaded) {
                this.loading = false
            }
        },
        preLoadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve();
                img.onerror = () => reject();
                img.src = src
            })
        }
    },
    mounted() {
        const timeout = setTimeout(() => {
            this.loading = false; // Ensure spinner hides after 2 seconds
        }, 2000)

        this.preLoadImage(this.imageSrc2)
            .then(() => {
                clearTimeout(timeout);
                this.dataLoaded = true;
                this.loading = false; // Hide spinner once loaded
            })
            .catch(() => {
                console.error('Failed to load icon');
                this.loading = false; // Ensure spinner hides on failure
            });
    }
};
</script>

<style scoped>
/* spinner styles */
.loading-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.spinner {
    border: 8px solid #6a70ff;
    border-top: 12px solid #fefaf2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.terms-container {
    width: 100%;
    max-width: 500px;
    /* Ensures it matches input fields & buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 0px;
    padding: 0;
    /* Removes any unwanted padding */
}

.terms-label {
    margin-top: -5px;
}

.checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    /* Adjusts space between checkbox and text */
    width: 100%;
    margin: 0;
    /* Ensures no extra spacing */
}

.custom-checkbox {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    cursor: pointer;
    appearance: none;
    border: 1px solid #B2B1AF;
    /* Border color */
    border-radius: 4px !important;
    /* Rounded corners */
    background-color: white;
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: 0px;
    padding: 0px;
    /* Removes unwanted margin */
}

/* Custom checkmark */
.custom-checkbox:checked::before {
    content: "✔";
    /* Unicode checkmark */
    font-size: 14px;
    color: #6A70FF;
    /* Blue check color */
    font-weight: bold;
    display: flex;
    align-content: flex-end;
    margin-left: 5px;
    position: absolute;
}

label {
    font-size: 14px;
    cursor: pointer;
    margin: 0;
}



.one {
    font-weight: 700;
}

.two {
    font-weight: 500;
}

.footer {
    background-color: #EDE8E4;
    border-top: 1px solid #DED8D3;
}

.container-color {
    background-color: #EDE8E4;
}

.inner-content {
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 8px;
    margin-top: 22px;
    max-width: 500px;
}

form {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10px; */
}

input {
    border-radius: 8px !important;
    font-size: 16px;
    padding: 14px 10px;
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 2px 5px rgba(128, 128, 128, 0.08),
        0 0px 1px rgba(128, 128, 128, 0.08);
}

input::placeholder {
    color: #8F8F8F;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #8F8F8F;
}

.btn-back {
    margin-right: 10px;
    width: 18px;
    height: 13px;
}

.btn-blue {
    background-color: #4373F0;
    color: white;
}


a {
    text-align: center;
    color: black;
    font-size: 16px;
    text-decoration: none;
}

.underline {
    display: inline-block;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
}


.input-wrapper {
    position: relative;
    display: inline-block;
}

.input-wrapper .error-mark {
    position: absolute;
    right: 15px;
    /* Adjust based on your design */
    top: 40%;
    transform: translateY(-50%);
    color: white;
    background-color: #FF5454;
    padding: 0px 7px;
    border-radius: 50%;
    pointer-events: none;
    /* Ensure the exclamation mark doesn't interfere with input */
}
</style>